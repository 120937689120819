import { atomWithStorage } from 'jotai/utils';

export const yearlyBannerCloseTimestampAtom = atomWithStorage<
  string | undefined
>('yearlyBannerCloseTimestamp', undefined);

export const dismissedLifeInsuranceLaunchOfferBannerAtom = atomWithStorage<
  boolean | undefined
>('dismissedLifeInsuranceLaunchOfferBannerAtom', false);

export const dismissedLifeInsuranceLaunchOfferBannerInsideSubscriptionFlowAtom =
  atomWithStorage<boolean | undefined>(
    'dismissedLifeInsuranceLaunchOfferBannerInsideSubscriptionFlowAtom',
    false
  );

export const subscriptionExpiredBannerCloseTimestampAtom = atomWithStorage<
  string | undefined
>('subscriptionExpiredBannerCloseTimestamp', undefined);

export const dismissedWaltioBannerAtom = atomWithStorage<boolean>(
  'dismissedWaltioBannerAtom',
  false
);

export const dismissedWaltioTopBannerAtom = atomWithStorage<boolean>(
  'dismissedWaltioTopBannerAtom',
  false
);
