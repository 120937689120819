import { formatNumber } from 'utils';

import { useUIState } from '../providers/UIProvider';

export const useFormatCurrencyYAxis = ({
  options,
  valuesToOmit,
  notSecret,
}: {
  notSecret?: boolean;
  options?: Intl.NumberFormatOptions;
  valuesToOmit?: number[];
} = {}) => {
  const { isSecretModeEnabled, locale, currency } = useUIState();

  return {
    formatCurrencyYAxis: (tickValue: number) => {
      if (valuesToOmit?.includes(tickValue)) {
        return '';
      }
      if (isSecretModeEnabled && !notSecret) {
        return '••••';
      }

      return formatNumber(tickValue, locale, {
        maximumFractionDigits:
          tickValue > 100_000 && tickValue < 1_000_000 ? 0 : 2,
        minimumFractionDigits: 0,
        compactDisplay: 'short',
        notation: 'compact',
        style: 'currency',
        currency,
        ...options,
      });
    },
  };
};
