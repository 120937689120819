import { DateRange } from 'utils';

import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import {
  ApiGetProps,
  AssetCategory,
  AssetListItem,
  AssetOrder,
} from '../types';
import { createSharingLinkParams } from '../utils';

import { useApi } from './useApi';

export type UseMoversProps = ApiGetProps & {
  periodDisplayMode: DateRange;
  categories?: AssetCategory[];
  sortFilter?: AssetOrder;
};

export const useMovers = ({
  categories,
  sortFilter,
  sharingLinkId,
  periodDisplayMode,
  accessCode,
}: UseMoversProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const categoriesFilter = categories?.join(',');

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('limit', '25');
  params.append('period', periodDisplayMode);
  if (sortFilter) {
    params.append('order', sortFilter);
  }
  if (categoriesFilter) {
    params.append('category', categoriesFilter);
  }

  return useApi<AssetListItem[]>(
    API_ROUTES.asset_list.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
