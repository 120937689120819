import { Box, BoxProps } from '../Box';
import { Icon, IconName } from '../Icon';
import { Typography } from '../Typography';

import { stepListStyle } from './StepList.css';

const StepDot = ({ resolved, ...props }: { resolved?: boolean } & BoxProps) => (
  <Box className={stepListStyle.dot({ resolved })} {...props} />
);

export type StepListItemProps = {
  description: string;
  icon: IconName;
  title: string;
  resolved?: boolean;
};

export type StepListProps = {
  items: StepListItemProps[];
  withUnavailableStepDisabled?: boolean;
};

export const StepList = ({
  items,
  withUnavailableStepDisabled = false,
}: StepListProps) => {
  const renderDots = (resolved?: boolean) =>
    new Array(3)
      .fill(null)
      .map((_, index) => (
        <StepDot
          key={index}
          resolved={resolved}
          marginTop={index === 0 ? 's0' : 's3'}
        />
      ));

  const renderItem = (item: StepListItemProps, index: number) => {
    const isLastItem = index === items.length - 1;
    const previousItem = items[index - 1];
    const isDisabled =
      withUnavailableStepDisabled && previousItem && !previousItem.resolved;

    return (
      <Box key={index} flexDirection="row" gap="s5" style={{ minHeight: 130 }}>
        <Box flexDirection="column">
          <Box backgroundColor="secondary" borderRadius="medium" padding="s4">
            <Icon
              name={item.resolved ? 'check' : item.icon}
              size="large"
              color={
                isDisabled ? 'disabled' : item.resolved ? 'success' : 'primary'
              }
            />
          </Box>

          {!isLastItem ? (
            <Box
              flex={1}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {renderDots(item.resolved)}
            </Box>
          ) : null}
        </Box>

        <Box flex={1} flexDirection="column" gap="s2" paddingBottom="s9">
          <Typography
            variant="bodyMediumEmphasis"
            color={isDisabled ? 'disabled' : 'primary'}
          >
            {item.title}
          </Typography>
          <Typography
            variant="bodyMedium"
            color={isDisabled ? 'disabled' : 'secondary'}
          >
            {item.description}
          </Typography>
        </Box>
      </Box>
    );
  };

  return <Box flexDirection="column">{items.map(renderItem)}</Box>;
};
