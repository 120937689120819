import { useAtomValue } from 'jotai';

import { providerSynchronizationAtom } from '/contexts/providerSynchronization';
import { firstVisitedPageAtom } from '/contexts/HistoryProvider';
import {
  isOnboardingAtom,
  isOnboardingTasksDismissedAtom,
} from '/contexts/onboarding';
import { advisorAtom } from '/contexts/advisor';
import { showAssetLimitationModalPaywallAtom } from '/contexts/paywall';
import { clerkSigninTicketAtom } from '/contexts/clerkSigninTicket';
import {
  cashflowCategoryTabPreferenceAtom,
  cashflowTabPreferenceAtom,
} from '/contexts/cashflow';
import {
  dismissedWaltioBannerAtom,
  dismissedWaltioTopBannerAtom,
  yearlyBannerCloseTimestampAtom,
  dismissedLifeInsuranceLaunchOfferBannerAtom,
  dismissedLifeInsuranceLaunchOfferBannerInsideSubscriptionFlowAtom,
} from '/contexts/banners';
import { portfolioBenchmarkAtom } from '/contexts/portfolioBenchmark';
import { portfolioOverviewSelectPreferenceAtom } from '/contexts/portfolioOverview';
import { portfolioCategoriesAtom } from '/contexts/portfolioCategories';
import {
  crowdlendingsDistributionTypeFilterAtom,
  cryptosDistributionTypeFilterAtom,
  distributionChartTypeAtom,
  investmentsDistributionTypeFilterAtom,
  otherAssetsDistributionTypeFilterAtom,
  realEstatesDistributionTypeFilterAtom,
} from '/contexts/distribution';
import {
  checkingAccountsTabPreferenceAtom,
  creditAccountsTabPreferenceAtom,
  investmentAccountsTabPreferenceAtom,
  investmentAccountsWrapperTabPreferenceAtom,
  savingAccountsTabPreferenceAtom,
} from '/contexts/tabPreferences';
import { signupValuesAtom } from '/contexts/signup';
import { portfolioAssetIsStackedChartAtom } from '/contexts/portfolioAsset';
import { feedbackAtom } from '/contexts/feedback';
import {
  assetCategoryFilterAtom,
  liabilityCategoryFilterAtom,
} from '/contexts/portfolioAssetsAndLiabilities';
import { moversCategoriesAtom, moversSortFilterAtom } from '/contexts/movers';
import { membershipIdAtom, organizationIdAtom } from '/contexts/organization';
import { dashboardCategoriesAtom } from '/contexts/dashboard';
import { investDepositPaymentModeAtom } from '/contexts/invest';
import { financialProjectionAtom } from '/contexts/simulation';
import {
  dismissedFinaryPlusModalTimestampAtom,
  dismissedInvestWithFinaryModalTimestampAtom,
  lifeInsuranceModalCloseTimestampAtom,
} from '/contexts/modals';
import { transactionSmartRuleSortTypeAtom } from '/contexts/transactions';

/** This component is used to preload storage atoms and prevent flicker on storage hydration  */
export const PreloadStorageAtoms = () => {
  // organization state
  useAtomValue(organizationIdAtom);
  useAtomValue(membershipIdAtom);

  // dashboard graph
  useAtomValue(dashboardCategoriesAtom);

  // distribution chart
  useAtomValue(distributionChartTypeAtom);

  // movers
  useAtomValue(moversCategoriesAtom);
  useAtomValue(moversSortFilterAtom);

  // portfolio categories
  useAtomValue(assetCategoryFilterAtom);
  useAtomValue(liabilityCategoryFilterAtom);
  useAtomValue(portfolioCategoriesAtom);

  // portfolio asset details
  useAtomValue(crowdlendingsDistributionTypeFilterAtom);
  useAtomValue(cryptosDistributionTypeFilterAtom);
  useAtomValue(investmentsDistributionTypeFilterAtom);
  useAtomValue(otherAssetsDistributionTypeFilterAtom);
  useAtomValue(portfolioAssetIsStackedChartAtom);
  useAtomValue(realEstatesDistributionTypeFilterAtom);

  // portfolio Tab preference
  useAtomValue(checkingAccountsTabPreferenceAtom);
  useAtomValue(creditAccountsTabPreferenceAtom);
  useAtomValue(savingAccountsTabPreferenceAtom);
  useAtomValue(investmentAccountsTabPreferenceAtom);
  useAtomValue(investmentAccountsWrapperTabPreferenceAtom);
  useAtomValue(cashflowTabPreferenceAtom);
  useAtomValue(cashflowCategoryTabPreferenceAtom);

  // portfolio Select preference
  useAtomValue(portfolioOverviewSelectPreferenceAtom);

  // signup
  useAtomValue(signupValuesAtom);

  // clerk signin ticket / impersonate
  useAtomValue(clerkSigninTicketAtom);

  // user feedback
  useAtomValue(feedbackAtom);

  // provider synchronization
  useAtomValue(providerSynchronizationAtom);

  // history
  useAtomValue(firstVisitedPageAtom);

  // Banners
  useAtomValue(yearlyBannerCloseTimestampAtom);
  useAtomValue(dismissedWaltioBannerAtom);
  useAtomValue(dismissedWaltioTopBannerAtom);
  useAtomValue(dismissedLifeInsuranceLaunchOfferBannerAtom);
  useAtomValue(
    dismissedLifeInsuranceLaunchOfferBannerInsideSubscriptionFlowAtom
  );

  // Modals
  useAtomValue(lifeInsuranceModalCloseTimestampAtom);
  useAtomValue(dismissedFinaryPlusModalTimestampAtom);
  useAtomValue(dismissedInvestWithFinaryModalTimestampAtom);

  // onboarding
  useAtomValue(isOnboardingAtom);
  useAtomValue(isOnboardingTasksDismissedAtom);

  // advisor
  useAtomValue(advisorAtom);

  // paywalls
  useAtomValue(showAssetLimitationModalPaywallAtom);

  // portfolio benchmark
  useAtomValue(portfolioBenchmarkAtom);

  // invest
  useAtomValue(investDepositPaymentModeAtom);

  // Portfolio simulation
  useAtomValue(financialProjectionAtom);

  // Cashflow preference
  useAtomValue(transactionSmartRuleSortTypeAtom);

  return null;
};
