import classNames from 'classnames';
import { Component, ReactNode, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput, {
  DefaultInputComponentProps,
  State,
  Props,
} from 'react-phone-number-input';

import { Box, BoxProps } from '../Box';
import { DropdownItem } from '../Dropdown';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
import { ErrorMessage } from '../TextField';
import { Typography } from '../Typography';

import 'react-phone-number-input/style.css';
import { phoneNumberFieldStyles } from './PhoneNumberField.css';

type CountrySelectProps = {
  onChange: (value?: string) => void;
  options: { label: string; value?: string }[];
  value?: string;
};

/**
 * Converts a two-letter country code into its corresponding flag emoji
 * Example: 'FR' -> '🇫🇷', 'US' -> '🇺🇸'
 * Works by converting each letter to its regional indicator symbol (Unicode 1F1E6-1F1FF)
 *
 * 1. split('') breaks the country code into individual letters: 'FR' -> ['F', 'R']
 * 2. map() converts each letter to its regional indicator symbol
 * 3. join('') combines the regional indicators back into a single string to form the flag
 */
const getFlag = (code: string) => {
  return code
    .toUpperCase()
    .split('') // 'FR' -> ['F', 'R']
    .map((c) => String.fromCodePoint(0x1f1e6 - 65 + c.charCodeAt(0))) // ['F', 'R'] -> ['🇫', '🇷']
    .join(''); // ['🇫', '🇷'] -> '🇫🇷'
};

const CountrySelect = ({
  value = 'FR',
  options,
  onChange,
}: CountrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      items={[
        options.map((option) =>
          option.value ? (
            <DropdownItem
              key={option.value}
              className={phoneNumberFieldStyles.country({
                active: value === option.value,
              })}
              onClick={() => onChange(option.value)}
            >
              <Box flexDirection="row" alignItems="center" gap="s3">
                <Typography className={phoneNumberFieldStyles.flag}>
                  {getFlag(option.value)}
                </Typography>
                {option.label}
              </Box>
            </DropdownItem>
          ) : null
        ),
      ]}
      align="start"
      side="bottom"
      onOpenChange={setIsOpen}
      className={phoneNumberFieldStyles.countryList}
    >
      <Box
        as="button"
        type="button"
        className={phoneNumberFieldStyles.selectedFlag}
      >
        <Typography className={phoneNumberFieldStyles.flag}>
          {getFlag(value)}
        </Typography>
        <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size="small" />
      </Box>
    </Dropdown>
  );
};

export type PhoneNumberFieldProps = {
  name: string;
  error?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
} & BoxProps;

export const PhoneNumberField = forwardRef<
  Component<
    Props<DefaultInputComponentProps>,
    State<Props<DefaultInputComponentProps>>
  >,
  PhoneNumberFieldProps
>(({ name, error, label, className, placeholder, ...props }, ref) => {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  return (
    <Box flexDirection="column" {...props}>
      <Box
        flexDirection="column"
        className={classNames(
          phoneNumberFieldStyles.container({ error: !!error }),
          className
        )}
      >
        <Typography
          as="label"
          htmlFor={name}
          className={phoneNumberFieldStyles.label}
        >
          {label}
        </Typography>
        <PhoneInput
          ref={ref}
          defaultCountry="FR"
          placeholder={placeholder}
          countryOptionsOrder={['FR', 'GB', 'US']}
          international
          addInternationalOption={false}
          countryCallingCodeEditable={false}
          numberInputProps={{ className: phoneNumberFieldStyles.formControl }}
          countrySelectComponent={CountrySelect}
          countrySelectProps={{ unicodeFlags: true }}
          value={value}
          onChange={(value) => setValue(name, value, { shouldValidate: true })}
        />
      </Box>
      {error ? <ErrorMessage message={error} /> : null}
    </Box>
  );
});

PhoneNumberField.displayName = 'PhoneNumberField';
