import classNames from 'classnames';
import { rgba } from 'polished';

import { animations } from '../../styles/animations.css';
import { brand } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';
import { Icon, IconProps } from '../Icon/Icon';
import { PieChart } from '../PieChart';

import {
  StatusIconStatus,
  StatusIconStyle,
  StatusIconSize,
  statusIconStyle,
} from './StatusIcon.css';

export type StatusIconProps = {
  status: StatusIconStatus;
  boxProps?: BoxProps;
  size?: StatusIconSize;
  style?: StatusIconStyle;
};

type NonLoadingStatus = NonNullable<Exclude<StatusIconStatus, 'loading'>>;

type IconSize = NonNullable<StatusIconSize>;

type IconStyle = NonNullable<StatusIconStyle>;

const mapIconConfig = (
  status: NonLoadingStatus,
  size: IconSize,
  style: IconStyle
): {
  iconColor: IconProps['color'];
  iconName: IconProps['name'];
  iconSize: IconProps['size'];
} => {
  const iconSizeMap: Record<IconSize, Record<IconStyle, IconProps['size']>> = {
    small: {
      fill: 'xxxsmall',
      outline: 'xsmall',
    },
    medium: {
      fill: 'xxsmall',
      outline: 'small',
    },
    large: {
      fill: 'small',
      outline: 'large',
    },
  };

  const smallMediumNameConfig: Record<
    IconStyle,
    Record<NonLoadingStatus, IconProps['name']>
  > = {
    fill: {
      success: 'checkRegular',
      pause: 'pauseRegular',
      warning: 'exclamationRegular',
      error: 'closeRegular',
    },
    outline: {
      success: 'circleCheck',
      pause: 'circlePause',
      warning: 'circleExclamation',
      error: 'circleXmark',
    },
  };

  const iconNameMap: Record<
    IconSize,
    Record<IconStyle, Record<NonLoadingStatus, IconProps['name']>>
  > = {
    small: smallMediumNameConfig,
    medium: smallMediumNameConfig,
    large: {
      fill: {
        success: 'check',
        pause: 'pause',
        warning: 'exclamation',
        error: 'close',
      },
      outline: {
        success: 'circleCheckThin',
        pause: 'circlePauseThin',
        warning: 'circleExclamationThin',
        error: 'circleXmarkThin',
      },
    },
  };

  const iconColorMap: Record<NonLoadingStatus, IconProps['color']> = {
    success: 'success',
    pause: 'warning',
    warning: 'warning',
    error: 'error',
  };

  const sizeKey = size === 'small' || size === 'medium' ? 'small' : 'large';

  return {
    iconName: iconNameMap[sizeKey][style][status],
    iconColor: iconColorMap[status],
    iconSize: iconSizeMap[size][style],
  };
};

export const StatusIcon = ({
  status = 'success',
  size = 'medium',
  style = 'outline',
  boxProps,
}: StatusIconProps) => {
  if (status === 'loading') {
    return (
      <Box
        className={classNames(
          statusIconStyle.container({ style, size, status }),
          animations.rotate
        )}
        {...boxProps}
      >
        <PieChart
          inert
          data={[
            {
              size: {
                small: { fill: 8, outline: 12 },
                medium: { fill: 12, outline: 16 },
                large: { fill: 16, outline: 24 },
              }[size][style],
              thickness: 1,
              data: [
                {
                  name: 'loader-background', // This is not displayed to the user, but mandatory in the Pie chart
                  share: 75,
                  color: rgba(brand.brand500, 0.3),
                },
                {
                  name: 'loader', // This is not displayed to the user, but mandatory in the Pie chart
                  share: 25,
                  color: brand.brand500,
                },
              ],
            },
          ]}
        />
      </Box>
    );
  }

  const { iconName, iconSize, iconColor } = mapIconConfig(status, size, style);

  return (
    <Box
      className={statusIconStyle.container({ style, size, status })}
      {...boxProps}
    >
      <Icon
        size={iconSize}
        name={iconName}
        color={iconColor}
        style={style === 'outline' ? { padding: 0 } : undefined}
      />
    </Box>
  );
};
