import { DateRange } from 'utils';

import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { ApiGetProps, AssetCategory, Portfolio } from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UsePortfolioProps = {
  periodDisplayMode: DateRange;
  categories?: AssetCategory[];
} & ApiGetProps;

export const usePortfolio = ({
  categories,
  sharingLinkId,
  accessCode,
  periodDisplayMode,
}: UsePortfolioProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('new_format', 'true');
  params.append('period', periodDisplayMode);

  if (categories && categories.length) {
    params.append('categories', categories.join(','));
  }

  return useApi<Portfolio>(
    API_ROUTES.portfolio.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
