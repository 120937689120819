import classNames from 'classnames';
import * as React from 'react';

import { useCombinedRefs } from '../../hooks/useCombinedRefs';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { Tag, TagProps } from '../Tag';
import { Typography } from '../Typography';

import { documentUploaderStyles } from './DocumentUploader.css';

export type DocumentUploaderFileStatus =
  | 'uploaded'
  | 'in_review'
  | 'approved'
  | 'rejected';

export type DocumentUploaderProps = Pick<
  React.ComponentProps<'input'>,
  'accept'
> & {
  onAddFile: (file: File) => void | Promise<void>;
  title: string;
  description?: string;
  file?: {
    name: string;
    status: DocumentUploaderFileStatus;
    statusTagText: string;
  };
  onRemoveFile?: () => void;
};

export const DocumentUploader = React.forwardRef<
  HTMLInputElement,
  DocumentUploaderProps
>(({ title, description, file, accept, onAddFile, onRemoveFile }, ref) => {
  const id = React.useId();
  const containerRef = React.useRef<HTMLLabelElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const isUploadActive =
    (!file || file.status === 'uploaded' || file.status === 'rejected') &&
    !isUploading;

  const combinedRef = useCombinedRefs(ref, inputRef);

  const addActiveClass = () =>
    containerRef.current?.classList.add(
      documentUploaderStyles.containerDragOver
    );

  const removeActiveClass = () =>
    containerRef.current?.classList.remove(
      documentUploaderStyles.containerDragOver
    );

  const onDragOver: React.DragEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();
    addActiveClass();
  };

  const handleLabelDrop: React.DragEventHandler<HTMLLabelElement> = async (
    event
  ) => {
    event.preventDefault();
    setIsUploading(true);
    const files = event.dataTransfer.files;

    if (files.length > 0 && files[0]) {
      await onAddFile(files[0]);
    }
    setIsUploading(false);
    removeActiveClass();
  };

  const getTagFromStatus = (
    status: Exclude<DocumentUploaderFileStatus, 'uploaded'>
  ) => {
    if (!file) {
      return null;
    }

    const STATUS_TAG_VARIANTS: Record<
      Exclude<DocumentUploaderFileStatus, 'uploaded'>,
      TagProps['variant']
    > = {
      in_review: 'default',
      approved: 'success',
      rejected: 'error',
    };

    return (
      <Tag size="small" variant={STATUS_TAG_VARIANTS[status]}>
        {file.statusTagText}
      </Tag>
    );
  };

  // used to remove active class when the user cancels the file upload
  React.useEffect(() => {
    const inputRefCurrent = inputRef.current;

    inputRefCurrent?.addEventListener('cancel', removeActiveClass);

    return () => {
      inputRefCurrent?.removeEventListener('cancel', removeActiveClass);
    };
  }, []);

  return (
    <Box
      tabIndex={0}
      as="label"
      draggable
      htmlFor={id}
      ref={containerRef}
      className={classNames(
        documentUploaderStyles.container,
        !isUploadActive && documentUploaderStyles.containerInactive
      )}
      onDragLeave={removeActiveClass}
      onDragOver={isUploadActive ? onDragOver : undefined}
      onDrop={isUploadActive ? handleLabelDrop : undefined}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ' || (event.key === 'Enter' && isUploadActive)) {
          event.preventDefault();
          inputRef.current?.click();
        }
      }}
      onClick={isUploadActive ? addActiveClass : undefined}
      flexDirection="column"
      gap="s5"
      paddingHorizontal={{ mobile: 's5', tablet: 's6' }}
      paddingVertical="s5"
    >
      <Box flex={1} alignItems="center">
        <Box backgroundColor="tertiary" borderRadius="full" padding="s3">
          <Icon name="file" size="small" />
        </Box>
        <Box flex={1} flexDirection="column" marginLeft="s5" marginRight="s7">
          <Typography variant="bodyLarge">{title}</Typography>
          {description && (
            <Typography variant="bodyMedium" color="tertiary">
              {description}
            </Typography>
          )}
        </Box>
        {isUploading ? (
          <Box
            borderRadius="full"
            padding="s3"
            className={documentUploaderStyles.iconContainer}
          >
            <Icon
              name="spinnerSolid"
              animation="rotate"
              size="small"
              color="tertiary"
            />
          </Box>
        ) : isUploadActive ? (
          <Box
            borderRadius="full"
            padding="s3"
            className={documentUploaderStyles.iconContainer}
          >
            <Icon name="arrowUpFromBracket" color="primary" size="small" />
          </Box>
        ) : null}
      </Box>
      {file ? (
        <Box flexDirection="column" gap="s3">
          <Box
            key={file.name}
            flexDirection="row"
            gap="s3"
            justifyContent="space-between"
          >
            <Typography variant="bodyMedium" color="secondary">
              {file.name}
            </Typography>
            {file.status === 'uploaded' && onRemoveFile ? (
              <Box<'button'>
                as="button"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onRemoveFile();
                }}
              >
                <Icon name="close" size="medium" color="primary" />
              </Box>
            ) : null}
            {file.status !== 'uploaded' ? getTagFromStatus(file.status) : null}
          </Box>
        </Box>
      ) : null}
      <input
        id={id}
        type="file"
        multiple={false}
        disabled={!isUploadActive}
        tabIndex={-1}
        accept={accept}
        ref={combinedRef}
        className={documentUploaderStyles.input}
        onChange={async (event) => {
          event.preventDefault();
          setIsUploading(true);
          const files = event.target.files ?? [];
          if (files.length > 0 && files[0]) {
            await onAddFile(files[0]);
          }
          setIsUploading(false);
        }}
      />
    </Box>
  );
});

DocumentUploader.displayName = 'DocumentUploader';
