import { atom, useAtom } from 'jotai';
import { DateRange } from 'utils';

import { useUIConfiguration } from './useUIConfiguration';

export const sharingPeriodDisplayModeAtom = atom<DateRange>('all');

export const usePeriodDisplayMode = (isSharing: boolean) => {
  const [sharingPeriodDisplayMode, setSharingPeriodDisplayMode] = useAtom(
    sharingPeriodDisplayModeAtom
  );
  const { data: configuration, update: updateUIConfiguration } =
    useUIConfiguration();
  return {
    data: isSharing
      ? sharingPeriodDisplayMode
      : configuration.period_display_mode,
    update: (periodDisplayMode: DateRange) =>
      isSharing
        ? setSharingPeriodDisplayMode(periodDisplayMode)
        : updateUIConfiguration({ period_display_mode: periodDisplayMode }),
  };
};
