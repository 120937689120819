import * as RadixTooltip from '@radix-ui/react-tooltip';
import { TooltipContentProps as RadixTooltipContentProps } from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import * as React from 'react';

import { TooltipContent, TooltipContentProps } from '../TooltipContent';

import { tooltipStyles, TooltipVariants } from './Tooltip.css';

export type TooltipProps = TooltipContentProps &
  TooltipVariants & {
    children: React.ReactNode;
    contentProps?: RadixTooltipContentProps;
    /**
     * You also have to e.preventDefault() in your onClick children if you want to keep the tooltip open after clicking
     */
    keepVisibleOnClick?: boolean;
    usePortal?: boolean;
  } & Pick<RadixTooltip.TooltipProps, 'open' | 'disableHoverableContent'>;

export const Tooltip = ({
  children,
  contentProps = {
    sideOffset: 4,
  },
  size,
  keepVisibleOnClick,
  usePortal,
  open,
  variant,
  ...tooltipContentProps
}: TooltipProps) => {
  const { className, style, ...rest } = contentProps ?? {};

  const content = (
    <RadixTooltip.Content
      avoidCollisions
      collisionPadding={8}
      onPointerDownOutside={
        keepVisibleOnClick
          ? (event) => {
              event.preventDefault();
            }
          : undefined
      }
      className={classNames(tooltipStyles({ variant }), className)}
      data-size={size}
      style={{ maxWidth: '392px', ...style }}
      {...rest}
    >
      <TooltipContent
        size={size}
        background={variant === 'chart' ? 'transparent' : 'default'}
        {...tooltipContentProps}
      />
    </RadixTooltip.Content>
  );

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={open} delayDuration={125}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        {usePortal ? (
          <RadixTooltip.Portal>{content}</RadixTooltip.Portal>
        ) : (
          content
        )}
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
