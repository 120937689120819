import * as React from 'react';

import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

import {
  rawIconStyles,
  selectButtonStyles,
  SelectButtonVariants,
} from './SelectButton.css';

type SelectButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & SelectButtonVariants &
  Pick<BoxProps, 'style'>;

export const SelectButton = React.forwardRef<
  HTMLButtonElement,
  SelectButtonProps
>(({ children, variant = 'normal', onClick, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={selectButtonStyles({ variant })}
      onClick={onClick}
      {...props}
    >
      <Box gap="s3" alignItems="center">
        {variant === 'raw' ? (
          children
        ) : (
          <Typography variant="labelXSmall" flex={1}>
            {children}
          </Typography>
        )}
        <Icon
          name="chevronDown"
          size="small"
          className={variant === 'raw' ? rawIconStyles : undefined}
        />
      </Box>
    </button>
  );
});

SelectButton.displayName = 'SelectButtton';
