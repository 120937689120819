import { API_ROUTES } from '../../../routes';
import { useApi } from '../../useApi';

type RealEstateAutocompleteWithoutDetailedAddress = {
  address: string;
  place_id: string;
};

export type RealEstateAutocompleteDetailedAddress = {
  city: string;
  country_code: string;
  line1: string;
  postal_code: string;
};

type RealEstateAutocompleteWithDetailedAddress =
  RealEstateAutocompleteWithoutDetailedAddress & {
    detailed_address: RealEstateAutocompleteDetailedAddress;
  };

export const useRealEstatesAddressAutocomplete = <T extends boolean = false>(
  query: string,
  withDetailedAddress: T = false as T
) => {
  const params = new URLSearchParams();
  params.append('query', query);
  params.append('detailed_address', `${withDetailedAddress}`);

  return useApi<
    T extends true
      ? RealEstateAutocompleteWithDetailedAddress[]
      : RealEstateAutocompleteWithoutDetailedAddress[]
  >(
    API_ROUTES.real_estates.autocomplete(params),
    { keepPreviousData: true, revalidateOnFocus: false },
    undefined,
    query.length === 0
  );
};
